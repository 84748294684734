import {v4 as uuidV4} from "uuid";

export function getIMEI() {
  if (process.browser && typeof window.localStorage !== 'undefined') {
    let IMEI = localStorage.getItem('IMEI');
    if (IMEI === null) {
      IMEI = uuidV4();
      localStorage.setItem('IMEI', IMEI);
    }

    return IMEI;
  }
  return '';
}

// 获取渠道
export function appCode() {
  const referrer = document.referrer.split('?')[1]
  const obj = {}
  if (referrer) {
    const referrArr = referrer.split('&');
    for (let i = 0; i < referrArr.length; i++) {
      obj[referrArr[i].split('=')[0]] = referrArr[i].split('=')[1]
    }
  }
  const nowParams = location.search.replace(/^\?/, '')
  const objParams = nowParams.split('&');
  const obj2 = {}
  for (let i = 0; i < objParams.length; i++) {
    obj2[objParams[i].split('=')[0]] = objParams[i].split('=')[1]
  }
  return Object.assign({}, obj, obj2)
}